import React from 'react'

const ModeDropDown = ({ managerMode, setManagerMode }) => {
    if (!managerMode || !setManagerMode) { return null }

    const handleChange = (value) => {
        console.log('event', value)
        console.log(typeof value)
        setManagerMode(value)
    }

    return (
        <div>
            <select id="mode" className="form-select form-select-sm mt-1" onChange={(event) => handleChange(event.target.value)} value={managerMode}>
                <option value="calendar">Mode: Calendar</option>
                <option value="unScheduledShipments">Mode: Unscheduled Shipments</option>
                <option value="unapprovedAppointments">Mode: Unapproved Appointments</option>
            </select>
        </div>
    )
}

export default ModeDropDown