import { get } from 'jquery'
import React, { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'

export const UserControl = ({ shipment, pickupOrDelivery }) => {

    let [consolidationShipment, setConsolidationShipment] = useState(null)
    let [allUsercontrols, setAllUsercontrols] = useState([])
    let [state, setState] = useState('start')

    useEffect(() => {
        if (shipment) {
            if (shipment.details.trip) {
                getShipmentDetails(shipment.details.trip)
            }
        }
    }, [shipment])

    async function getShipmentDetails(webquotecontrol) {
        setState('loading')
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://api.shippersedge.com/v4/shipment/" + webquotecontrol + '/?include=stopDetails', requestOptions)
            .then(response => response.text())
            .then(result => {
                setState('success')
                setConsolidationShipment(JSON.parse(result))
            })
            .catch(error => {
                setState('error')
                console.error('UserControl getShipmentDetails error', error)
            });
    }

    useEffect(() => {
        if (consolidationShipment) {
            let theStop = theStopForThisShipment(shipment.id, pickupOrDelivery, consolidationShipment)
            let allUCs = []
            for (const order of theStop.orders) {
                allUCs.push(order.usercontrol)
            }
            setAllUsercontrols(allUCs)

        }
    }, [consolidationShipment, pickupOrDelivery, shipment])

    const theStopForThisShipment = (webquotecontrol, pickupOrDelivery, consolidationShipment) => {
        let validStops = consolidationShipment.stops.filter(stop => stop.type === pickupOrDelivery)
        for (const stop of validStops) {
            for (const order of stop.orders) {
                if (parseInt(order.id) === webquotecontrol) {
                    return stop
                }
            }
        }
        return null
    }

    if (!shipment || !pickupOrDelivery) { return null }



    if (state === 'loading') {
        return (
            <>
                <Fade>
                    <br />
                    <div className='d-flex flex-row justify-content-center align-items-center ' onClick={() => getShipmentDetails(shipment.details.trip)} style={{ cursor: 'pointer' }}>
                        <div className="spinner-border text-primary" role="status">

                        </div>
                    </div>
                </Fade>
            </>)
    }


    if (state === 'error' && shipment.details.trip) {
        return (
            <>
                <Fade>
                    <br />
                    <div className='d-flex flex-row justify-content-center align-items-center ' onClick={() => getShipmentDetails(shipment.details.trip)} style={{ cursor: 'pointer' }}>
                        <div className="alert alert-danger border border-warning" role="alert">
                            Error loading reference numbers, click to retry.
                        </div>
                    </div>
                </Fade>
            </>)
    }

    if (shipment && !consolidationShipment && state === 'start') {
        return (
            <span className='m-2'>{shipment.usercontrol}</span>
        )
    }
    if (shipment && consolidationShipment && allUsercontrols) {
        return (
            <Fade>
                <span className=''>
                    <ul>
                        {allUsercontrols.map((uc, index) => {
                            return (
                                <li key={index}><strong>{uc}</strong></li>
                            )
                        })}
                    </ul>
                </span>
            </Fade>
        )
    } else {
        return null
    }
}
