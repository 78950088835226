import React, { useEffect, useState } from 'react'
import Carrier from '../Carrier/Carrier'
import PoweredByFooter from '../Footer/PoweredByFooter'
import CarrierNavBar from '../Carrier/Components/CarrierNavBar'
import { Fade } from 'react-awesome-reveal'
// BreakoutCarrier exists to handle Consolidations

const BreakoutCarrier = (props) => {

    let breakoutCarrierDebug = false
    const driverCode = props.match.params.driverCode
    
    const searchParams = new URLSearchParams(props.location.search)
    console.log('searchParams', searchParams.get('showHeader'))
    const showHeader = !searchParams.get('showHeader') || searchParams.get('showHeader') === 'true' ? true : false
    const showFooter = !searchParams.get('showFooter') || searchParams.get('showFooter') === 'true' ? true : false
    console.log('showHeader', showHeader)
    console.log('showFooter', showFooter)
    const [isConsolidation, setIsConsolidation] = useState(false)
    const [consolidationDriverCodes, setConsolidationDriverCodes] = useState([])
    const [breakoutCarrierStatus, setBreakoutCarrierStatus] = useState('loading')
    const [client, setClient] = useState("")

    useEffect(() => {
        if (!driverCode) {
            setBreakoutCarrierStatus('noDriverCode')
            return
        }
        checkIfConsolidation(driverCode)
    }, [driverCode])

    const checkIfConsolidation = async (driverCode) => {
        // console.log('checkIfConsolidation', driverCode)
        let url = 'https://api.shippersedge.com/schedule/isConsolidation/' + driverCode
        let myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        let request = new Request(url, requestOptions);

        let checkIfConsolidationResponse = await fetch(request)
        let checkIfConsolidationResponseJSON = await checkIfConsolidationResponse.json()
        console.log('checkIfConsolidationResponse', checkIfConsolidationResponseJSON.stopsDriverCodes)
        if (checkIfConsolidationResponseJSON.isConsolidation) {
            setIsConsolidation(true)
            setConsolidationDriverCodes(checkIfConsolidationResponseJSON.stopsDriverCodes)
            setClient(checkIfConsolidationResponseJSON.client)
            setBreakoutCarrierStatus('consolidation')
        } else {
            setClient(checkIfConsolidationResponseJSON.client)
            setBreakoutCarrierStatus('notConsolidation')
        }
    }

    const handleReload = () => {
        window.location.reload(true);
    };

    if (breakoutCarrierStatus === 'loading') {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="d-flex flex-column">
                            <div>
                                <img src="https://scheduler.shippersedge.com/shippersedge-logo.png" alt="logo" height="200px" className="img-fluid" />
                            </div>
                            {/* <span className="TitleText mt-3">Dock Scheduler</span> */}
                            <div className="d-flex flex-row justify-content-center">
                                {/* <LoadingIcon /> */}
                                <img src="https://scheduler.shippersedge.com/loading.gif" alt="loading icon" height="300px" title="Loading" />
                            </div>
                            <div >Loading Shipment.... </div>

                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
            </div >
        )
    }

    if (breakoutCarrierStatus === 'noDriverCode') {
        return <div>No Driver Code</div>
    }

    if (breakoutCarrierStatus === 'error') {
        return <div>Error <button className="btn btn-primary" onClick={handleReload}>Click to reload the page.</button></div>
    }

    if (isConsolidation && consolidationDriverCodes.length) {
        return (
            <>
                {breakoutCarrierDebug ? <div>Consolidation</div> : null}
                {showHeader ? <CarrierNavBar client={client} /> : null}
                {consolidationDriverCodes.length > 1 ?
                    <div className="d-flex justify-content-center">
                        <div className="alert alert-success border-dark" role="alert">
                            There are {consolidationDriverCodes.length} stops that require an appointment on the Dock Scheduler.<br />  Scroll down to see all stops.
                        </div>
                    </div> : null}

                {consolidationDriverCodes.map((driverCode, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Fade>
                                < div className='mt-3' >
                                    {breakoutCarrierDebug ? <div>{driverCode}</div > : null}
                                    <Carrier driverCode={driverCode} isConsolidation={true} stopNumber={index} />
                                </div >
                            </Fade>
                        </React.Fragment>
                    )
                })}
                {showFooter ? <PoweredByFooter isConsolidation={isConsolidation} /> : null}
            </>
        )

    }

    if (!isConsolidation) {
        return (
            <>

                {showHeader ? <CarrierNavBar client={client} /> : null}
                {breakoutCarrierDebug ? <div>Not consolidation</div> : null}
                <Carrier driverCode={driverCode} isConsolidation={false} />
                {showFooter ? <PoweredByFooter isConsolidation={isConsolidation} /> : null}

            </>
        )
    }


    return (
        <div className="container">
            <div className="alert alert-warning mt-3" role="alert">
                <h3>Breakout Carrier Error</h3>
                <p>There was an error processing the breakout carrier request.</p>
                <p>Please contact support.</p>
            </div>
        </div>

    )
}

export default BreakoutCarrier