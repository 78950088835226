import React, { useState, useEffect, useContext } from 'react'

const Colors = ({ shipment, appointmentProps }) => {
    const [color, setColor] = useState(null)


    const colorAndNames = [
        {
            HTML_Color: null,
            name: 'No Color'
        },
        {
            HTML_Color: 'FE6544',
            name: 'Late'
        },
        {
            HTML_Color: '58D68D',
            name: 'Unloaded'
        }
    ]

    useEffect(() => {
        if (appointmentProps?.dayCustomColor) {
            let colorAndName = colorAndNames.find(colorAndName => colorAndName.HTML_Color === appointmentProps.dayCustomColor)
            if (!colorAndName) {
                colorAndName = colorAndNames.find(colorAndName => colorAndName.HTML_Color === null)
            }
            setColor(colorAndName)
        }
    }, [appointmentProps])

    useEffect(() => {

    }, [shipment])





    const saveColor = async (HTML_Color) => {
        let body = {
            appointmentId: appointmentProps.id,
            color: HTML_Color
        }
        let url = `https://api.shippersedge.com/schedule/colorCategories/`
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            },
            body: JSON.stringify(body)
        })
        let data = await response.json()
    }

    const success = (
        // green checkbox
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>

    )

    const error = (
        <div className="" role="alert">
            <b><h5>Error!</h5></b>
        </div>
    )

    const savingSpinner = (
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )


    return (
        <>

            <div className="card ms-2 mt-2 pe-2">
                {/* Radio buttons generated by colorAndNames */}
                <div className="form-check mt-2">
                    {colorAndNames.map((colorAndName, index) => {
                        return (
                            <div className="form-check">
                                <input className="form-check-input e-field" type="radio" name="apptColor" id={colorAndName.name} defaultValue={colorAndName.HTML_Color} onClick={() => saveColor(colorAndName.HTML_Color)} />
                                <label className="form-check-label d-flex flex-row" htmlFor={colorAndName.name}>
                                    {colorAndName.name} <div className="ms-3 border border-black" style={{ width: 20, height: 20, backgroundColor: `#${colorAndName.HTML_Color}` }} />
                                </label>
                            </div>
                        )
                    })}

                </div>
            </div>


        </>

    )
}

export default Colors