import React from 'react'

const SelectSupplier = ({ supplierWindowsMode, suppliers, selectedSuppliers, setSelectedSuppliers }) => {
    const [currentlySelectedSupplierId, setCurrentlySelectedSupplierId] = React.useState(null)
    const [enableAddSupplierButton, setEnableAddSupplierButton] = React.useState(false)

    if (!supplierWindowsMode) { return null }
    if (!suppliers) { return null }




    const addSupplier = () => {
        // console.log('addSupplier', currentlySelectedSupplierId)
        if (!currentlySelectedSupplierId) { return }
        if (currentlySelectedSupplierId === "0") { return }

        // don't add suppliers already in the selectedSuppliers list
        let alreadySelected = selectedSuppliers.find(supplier => supplier.id === parseInt(currentlySelectedSupplierId))
        if (alreadySelected) { return }

        let supplier = suppliers.find(supplier => supplier.id === parseInt(currentlySelectedSupplierId))

        // console.log('addSupplier', supplier)
        let newSelectedSuppliers = [...selectedSuppliers]
        newSelectedSuppliers.push(supplier)
        setSelectedSuppliers(newSelectedSuppliers)

    }

    const curentSelectedSupplier = (e) => {
        // console.log('curentSelectedSupplier', e.target.value)
        if (e.target.value === "0") {
            setEnableAddSupplierButton(false)
            return
        } else {
            setEnableAddSupplierButton(true)
        }
        setCurrentlySelectedSupplierId(e.target.value)
    }

    return (
        <>
            {/* Select of suppliers */}
            <div className="selectSupplier">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="supplier">Select Supplier</label>
                            <div className="input-group">

                                <select className="form-control" id="supplier" onChange={(e) => curentSelectedSupplier(e)}>
                                    <option value={0}>Select</option>
                                    {suppliers.map((supplier, index) => {

                                        return (
                                            <option key={index} value={supplier.id}>{supplier.name}</option>
                                        )
                                    })}
                                </select>
                                {/* add supplier button */}
                                <button className="btn btn-outline-secondary" disabled={!enableAddSupplierButton} onClick={() => addSupplier()}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectSupplier