import React from 'react'
import { Fade } from 'react-awesome-reveal'
// import LoadingIcon from '../../LoadingIcon/LoadingIcon'

const Loading = () => {
    return (
        <Fade>
            <div className="container">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="d-flex flex-column justify-content-center">
                            {/* <a href="https://www.shippersedge.com/"><img src="../../../shippersedge-logo.png" alt="logo" height="100px" /></a> */}
                            {/* <div>
                            <img src="https://scheduler.shippersedge.com/shippersedge-logo.png" alt="logo" height="200px" className="img-fluid" />
                        </div>
                        <span className="TitleText mt-3">Dock Scheduler</span> */}
                            {/* <div className="alert alert-secondary" role="alert"> */}

                            {/* </div> */}
                            <div className="d-flex flex-row justify-content-center">
                                {/* <LoadingIcon /> */}
                                <img src="https://scheduler.shippersedge.com/loading.gif" alt="loading icon" height="300px" />
                            </div>
                            <div >Loading Appointments.... </div>
                            {/* <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div> */}
                        </div>
                        <div className="col-3"></div>
                    </div>
                </div>
            </div >
        </Fade>
    )
}

export default Loading
