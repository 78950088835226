import React from 'react'
import { useSearchResults } from '../../../../utils/Contexts/SearchResultsContext'
import { Col, Row } from 'react-bootstrap'
import {Fade} from 'react-awesome-reveal'
import ApptEditorTemplateShipmentDetails from '../../SchedulerComponents/ApptEditorTemplateComponents/ApptEditorTemplateShipmentDetails'

const SearchResults = () => {

    const { searchResults, setSearchResults, searchState, setSearchState } = useSearchResults()


    const clearSearchResults = () => {
        setSearchResults(null)
        setSearchState('start')
    }



    if (!searchResults) { return null }

    if (searchState === 'loading') {
        return (
            <Col>
                <Fade>
                    <Row>
                        <div className="d-flex flex-row justify-content-center p-5">
                            <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem' }}>

                            </div>
                        </div>

                    </Row>
                </Fade>
            </Col>
        )
    }

    if (searchResults.length > 0) {
        return (
            <Col>
                <Fade>
                    <Row>
                        <div className='card mt-1 mb-1 border-dark'>
                            <div className='card-body'>
                                <div className='d-flex flex-row justify-content-between'>
                                    <div>
                                        <h5 className="card-title text-start">
                                            Search Results
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search ms-2" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg>
                                        </h5>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16" onClick={() => clearSearchResults()} style={{ cursor: 'pointer' }}>
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className='card-group d-flex flex-row justify-content-center'>
                                    {searchResults.map((shipment, index) => {
                                        let timeInString = ''
                                        // let timeOutString = ''
                                        if (shipment.apptDetails.length > 0) {
                                            // take apptDetials[0].timeIn "2023-08-03T10:15:00.000Z" and set to a human readable format
                                            let timeIn = new Date(shipment.apptDetails[0].timeIn)
                                            // convert to local time

                                            timeInString = timeIn.toLocaleString('en-US', { timeZone: 'UTC' })

                                            // let timeOut = new Date(shipment.apptDetails[0].timeOut)
                                            // timeOutString = timeOut.toLocaleString()

                                        }


                                        return (
                                            <div key={index} className="card" style={{ maxWidth: "75%" }}>
                                                <div className='d-flex flex-row card-header'>
                                                    <div className='col-4'>

                                                    </div>
                                                    <div className='col-4'>
                                                        <p className="card-text">{shipment.usercontrol}</p>
                                                    </div>
                                                    <div className='col-4'>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <ApptEditorTemplateShipmentDetails shipment={shipment} isConsolidation={shipment?.isConsolidation} consolidationShipment={shipment?.consolidationShipment} />
                                                    </div>
                                                    {shipment?.apptDetails?.length ?
                                                        <>
                                                            <div className="alert alert-success border-dark" title="Appointment Scheduled">
                                                                <small className="text-muted" style={{ fontSize: "12px" }}>Appointment Scheduled</small><br />
                                                                {timeInString}<br />
                                                                {shipment?.apptDetails[0]?.dockLocationName} Dock: {shipment?.apptDetails[0]?.dockName} <br />
                                                                <small className="text-muted" style={{ fontSize: "12px" }}>Id {shipment?.apptDetails[0]?.id}</small>
                                                            </div>


                                                        </>
                                                        :
                                                        <div className="alert alert-light border-dark">No appointment scheduled.</div>
                                                    }
                                                </div>

                                                <div className='card-footer'>
                                                    <small className="text-muted" style={{ fontSize: "10px" }}>Id: {shipment.id}</small>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Row>
                </Fade>
            </Col>
        )

    }
    if (searchResults.length === 0 && searchState === 'success') {
        return (
            <Col>
                <Fade>
                    <Row>
                        <div>No orders found.</div>
                    </Row>
                </Fade>
            </Col>
        )
    } else {
        return (
            null
            // <Col>
            //     <Row>
            //         <div>SearchResults sees nothing</div>
            //         <button onClick={() => console.log('wat', searchResults)}>wat</button>
            //     </Row>
            // </Col>
        )
    }
}

export default SearchResults