import React, { useState, useEffect } from 'react'
import AuthUIMessages from './AuthUIMessages'
import * as Sentry from "@sentry/react";

const AuthUI = () => {

    const [authorized, setAuthorized] = useState()

    const urlParams = new URLSearchParams(window.location.search)
    const cftoken = urlParams.get('cftoken')
    const currentURL = window.location.href

    let isKwiktrip = false
    if (localStorage.getItem('usercode') === 'kwiktrip') {
        isKwiktrip = true
    }

    const checkToken = async (cftoken) => {
        if (!cftoken) {
            setAuthorized(false)
            return
        }
        const response = await fetch(
            `https://api.shippersedge.com/authenticate/cfSession/?sessionId=${cftoken}`
        )
        const status = response.status

        console.log('status', status)

        if (status === 200) {
            setAuthorized(true)
        } else {
            setAuthorized(false)
        }

        let data = await response.json()
        return data
    }

    useEffect(() => {
        if (cftoken) {
            localStorage.setItem('cftoken', cftoken)
        }
        checkToken(cftoken)

    }, [])


    if (!cftoken || authorized === false) {
        // send alert to sentry
        const eventName = 'No Token detected'
        Sentry.captureEvent({
            message: `${eventName}`,
            level: 'info',
        })

        let baseUrl = 'https://app.shippersedge.com/'
        switch (localStorage.getItem('usercode')) {
            case 'kwiktrip':
                baseUrl = 'https://kwiktrip.shippersedge.com/'
                break
            case 'foremost':
                baseUrl = 'https://foremost.shippersedge.com/'
                break
            case 'sleepcountry':
                baseUrl = 'https://sleepcountry.shippersedge.com/'
                break
            case 'pickseed':
                baseUrl = 'https://pickseed.shippersedge.com/'
                break
            case 'acmebumn':
                baseUrl = 'https://demo.shippersedge.com/'
                break
            case 'vandelay':
                baseUrl = 'https://vandelay.shippersedge.com/'
                break
            case 'kissner':
                baseUrl = 'https://kissner.shippersedge.com/'
                break
            case 'glorybee':
                baseUrl = 'https://glorybee.shippersedge.com/'
                break
            case 'ussalt':
                baseUrl = 'https://ussalt.shippersedge.com/'
                break
            case 'americanwater':
                baseUrl = 'https://americanwater.shippersedge.com/'
                break
            case 'action':
                baseUrl = 'https://action.shippersedge.com/'
                break
            default:
                baseUrl = 'https://app.shippersedge.com/'
        }
        

        // redirect user to app.shippersedge.com/auth/login
        window.location.href = baseUrl + 'auth/login.cfm?redirectURL=' + currentURL
    }

    //     return (
    //         <div className="d-flex flex-row justify-content-center mb-2">
    //             {isKwiktrip ? AuthUIMessages.noTokenKwiktrip : AuthUIMessages.noToken}
    //         </div>
    //     )
    // }

    // Right now just putting this code out as a catch to see if we hear from folks using the Dock Manager without the new links that have been available for a months now.

    // return (
    //     null
    // )

    if (authorized === false) {
        return (
            <div className="d-flex flex-row justify-content-center mb-2">
                {AuthUIMessages.invalidToken}
            </div>
        )
    }

    // if (authorized === true) {
    //     return (
    //         <div className="d-flex flex-row justify-content-center mb-2">
    //             {AuthUIMessages.tokenValid}
    //         </div>
    //     )
    // }

    return (
        null
    )
}

export default AuthUI