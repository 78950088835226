import React, { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'

const ApprovedAppt = ({ role, appointment }) => {

    const [status, setStatus] = useState('start')
    const [approved, setApproved] = useState(null)

    let debug = false

    useEffect(() => {
        if (typeof appointment !== 'undefined' && typeof appointment.approved !== 'undefined') {
            setApproved(appointment.approved)
        }
    }, [appointment])

    const handleApprovedClick = (choice) => {
        setStatus('loading')
        setApproved(choice)
        updateApprovedViaDSBackEnd(appointment.id, choice)
    }

    const updateApprovedViaDSBackEnd = async (apptID, choice) => {

        let url = `https://api.shippersedge.com/schedule/apptApprovedStatus/` + apptID + '/' + choice

        let response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            }
        })
        let data = await response.json()
        // console.log('ApprovedAppt data', data)
        if (response.status === 200) {
            setStatus('success')
        } else {
            setStatus('error')
        }
    }

    const StatusPannel = () => {
        if (status === 'start' && role === 'carrier') {
            return null
        }
        if (status === 'error') {
            return (
                <div className='text-danger'>
                    Error
                </div>
            )
        }
        if (status === 'success' && role === 'carrier') {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" className="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
            )
        }
        if (status === 'loading' && role === 'carrier') {
            return (
                <div className="spinner-border text-primary" role="status">
                </div>
            )
        } else {
            return null
        }
    }

    // Placeholder
    // if (1 === 1) {
    //     return (
    //         <div>Approved or not</div>
    //     )
    // }

    if (!role || !appointment) {
        return null
    }

    let disableFade = false
    if (role === 'carrier') {
        disableFade = true
    }

    if (role && appointment) {
        return (
            <Fade disabled={disableFade}>
                <div id="approvedAppt" className='card mt-2 d-flex flex-row ms-2'>
                    <div id="approvedApptInfo" className="card-body ">
                        <div className="form-check">
                            {
                                approved === 1

                                    ? <input className="form-check-input" type="radio" name="approvedApptRadio" id="approvedApptRadio" checked onClick={() => handleApprovedClick(1)} />

                                    : <input className="form-check-input" type="radio" name="approvedApptRadio" id="approvedApptRadio" onClick={() => handleApprovedClick(1)} />
                            }
                            <label className="form-check-label" htmlFor="approvedApptRadio">
                                Approved
                            </label>
                        </div>
                        <div className="form-check">
                            {
                                approved === 0

                                    ? <input className="form-check-input" type="radio" name="notApprovedRadio" id="notApprovedRadio" checked onClick={() => handleApprovedClick(0)} />

                                    : <input className="form-check-input" type="radio" name="notApprovedRadio" id="notApprovedRadio" onClick={() => handleApprovedClick(0)} />
                            }
                            <label className="form-check-label" htmlFor="notApprovedRadio">
                                Not Approved
                            </label>

                        </div>
                    </div>
                    <div id="notApprovedRadioStatus" className='d-flex flex-row justify-content-center align-items-center flex-grow-1'>
                        <StatusPannel />
                    </div>
                </div>
            </Fade>
        )
    } else {
        console.warn('ApprovedAppt returning null but should not reach here.')
        return null
    }


}

export default ApprovedAppt