import React from 'react'

const IncludeExclude = ({ supplierWindowsMode, supplierRuleOperator, setSupplierRuleOperator }) => {

    if (!supplierWindowsMode) { return null }



    return (

        <div className="form-group">
            <label htmlFor="includeExclude">Include / Exclude</label>
            <div className="form-check">
                {supplierRuleOperator === 'include' ?

                    <input className="form-check-input" type="radio" name="includeExclude" id="include" defaultValue="include" checked onClick={() => setSupplierRuleOperator('include')} />

                    : <input className="form-check-input" type="radio" name="includeExclude" id="include" defaultValue="include" onClick={() => setSupplierRuleOperator('include')} />}

                <label className="form-check-label" htmlFor="include">
                    Include Suppliers
                </label>
            </div>
            <div className="form-check">

                {supplierRuleOperator === 'exclude' ?
                    <input className="form-check-input" type="radio" name="includeExclude" id="exclude" defaultValue="exclude" checked onClick={() => setSupplierRuleOperator('exclude')} /> :

                    <input className="form-check-input" type="radio" name="includeExclude" id="exclude" defaultValue="exclude" onClick={() => setSupplierRuleOperator('exclude')} />}
                <label className="form-check-label" htmlFor="exclude">
                    Exclude Suppliers
                </label>
            </div>
            {/* hidden input with a class of e-field e-input with the supplierRuleOperator*/}
            <input name="supplierRuleOperator" type="text" className="hidden e-field e-input" defaultValue={supplierRuleOperator} />
        </div>

    )
}

export default IncludeExclude