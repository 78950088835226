import React from 'react'
import EventTemplateDayRRuleCustomFields from './EventTemplateDayComponents/EventTemplateDayRRuleCustomFields'
import DetailsAndModal from './DetailsModal/DetailsAndModal'


const EventTemplateDay = (props, selectedDock, localusercode, currentlySelectedDate) => {
    // console.log('EventTemplateDay props', props)
    if (!props) {
        return null
    }

    let showDeliveryIcons = true
    if (props.usercode === 'kwiktrip' || props.usercode === 'sleepcountry') {
        showDeliveryIcons = false
    }

    // if props.webquotecontrol includes a comma
    let oneApptMultipleOrders = false
    if (props.webquotecontrol && props.webquotecontrol.includes(',')) {
        oneApptMultipleOrders = true
    }

    // let equipment = null
    // if (props.equipment) {
    //     equipment = "(" + props.equipment + ")"
    // }

    let consolidationMode = false
    let consolidationOrders = []
    if (props?.isConsolidation && props.isConsolidation === 1) {
        consolidationMode = true
        if (props.type === "Pickup") {
            if (props.stops?.pickup?.orders?.length) {
                for (let order of props.stops.pickup.orders) {
                    consolidationOrders.push(order.usercontrol)
                }
            }
        }
        if (props.type === "Delivery") {
            if (props.stops?.delivery?.orders?.length) {
                for (let order of props.stops.delivery.orders) {
                    consolidationOrders.push(order.usercontrol)
                }
            }
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    const ConsolidationUserControls = ({ props }) => {

        if (!props) return null

        let usercontrols

        if (props?.usercontrol && props.usercontrol.includes(',')) {
            usercontrols = props.usercontrol.split(',')
        } else {
            usercontrols = [props.usercontrol]
        }

        if (usercontrols && usercontrols.length) {
            return (
                <>
                    {usercontrols.map((cat, index) => {
                        return (
                            <div key={index}>
                                <span>{cat}</span><br />
                            </div>
                        )
                    })}
                </>
            )
        } else {
            return null
        }


        // if (props.type === "Pickup") {
        //     if (!props?.stops?.pickup?.orders) return null
        //     return (
        //         <>
        //             {props.stops.pickup.orders.map((cat, index) => {
        //                 return (
        //                     <div key={index}>
        //                         <span>{cat.usercontrol}</span><br />
        //                     </div>
        //                 )
        //             })}
        //         </>
        //     )
        // }
        // if (props.type === "Delivery") {
        //     if (!props?.stops?.delivery?.orders) return null
        //     return (
        //         <>
        //             {props.stops.delivery.orders.map((cat, index) => {
        //                 return (
        //                     <div key={index}>
        //                         <span>{cat.usercontrol}</span><br />
        //                     </div>
        //                 )
        //             })}
        //         </>
        //     )
        // }
    }

    const ConsolidationPackagesAndItems = ({ props }) => {
        if (!props) return null
        if (props.type === "Pickup") {
            if (!props?.stops?.pickup?.orders) return null
            return (
                <div>
                    {props.stops.pickup.orders.map((order, index) => {
                        return (
                            <div key={index}>
                                {order.handlingUnits.map((hu, index) => {
                                    return (
                                        <div key={index}>
                                            <span>Quantity: {hu.quantity} {capitalizeFirstLetter(hu.packaging)} Weight: {hu.details.weight}</span><br />
                                        </div>
                                    )
                                })}

                            </div>
                        )
                    })}
                </div>
            )
        }
        if (props.type === "Delivery") {
            if (!props?.stops?.delivery?.orders) return null
            return (
                <div className="">
                    {props.stops.delivery.orders.map((order, index) => {
                        return (
                            <div key={index}>
                                {order.handlingUnits.map((hu, index) => {
                                    return (
                                        <div key={index}>
                                            <span>Quantity: {hu.quantity} {capitalizeFirstLetter(hu.packaging)} Weight: {hu.details.weight}</span><br />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    const FirstSection = ({ props, consolidationMode, consolidationOrders, oneApptMultipleOrders }) => {

        if (consolidationMode && props.consolidationCarrierName) {
            return (
                <>
                    {!props.approved ? <span><strong>Not Approved</strong></span> : null}
                    <span><strong>{props.consolidationCarrierName}</strong></span><br />
                    <ConsolidationUserControls props={props} />
                    <ConsolidationPackagesAndItems props={props} />

                    <EventTemplateDayRRuleCustomFields props={props} localusercode={localusercode} currentlySelectedDate={currentlySelectedDate} />

                </>
            )
        }

        if (!consolidationMode) {
            // if props.usercontrol includes a comma replace the commas with two spaces
            let usercontrol = props.usercontrol
            if (props.usercontrol && props.usercontrol.includes(',')) {
                usercontrol = props.usercontrol.replace(/,/g, '  ')
            }

            return (
                <>
                    {props.usercode === 'sleepcountry' && props.type === "Pickup" ? <><strong>Pickup</strong><br /></> : null}
                    {props.usercode === 'sleepcountry' && props.type === "Delivery" ? <><strong>Delivery</strong><br /></> : null}
                    {props?.approved && !props.approved ? <div style={{ color: "red" }}><strong>Not Approved</strong></div> : null}
                    {props.carriername && props.carriername !== "" ? <><span><strong>{props.carriername}</strong></span><br /></> : null}
                    {props.supplierName ? <><span><strong>{props.supplierName}</strong></span><br /></> : null}
                    {(!props.carriername || props.carriername !== "") && props.description !== "" ? <><span><strong>{props.description}</strong></span><br /></> : null}
                    {usercontrol && usercontrol !== "" && !props.carriername ? <><strong>{usercontrol}</strong><br /></> : null}
                    {usercontrol && usercontrol !== "" && props.carriername ? <>{usercontrol}<br /></> : null}
                    {props.packageQuantity && !oneApptMultipleOrders ? <><span>Quantity: {props.packageQuantity} Weight: {Math.round(props.totalWeight)}</span><br /></> : null}
                    {oneApptMultipleOrders ? <><span>Open appointment for details.</span><br /></> : null}
                    <EventTemplateDayRRuleCustomFields props={props} localusercode={localusercode} currentlySelectedDate={currentlySelectedDate} />
                </>
            )

        } else {
            return null
        }
    }

    const PickupIconOld = ({ props }) => {
        return (
            <div title="Pickup">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-up-right-square-fill p-1 ms-1" viewBox="0 0 16 16">
                    <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z" />
                </svg>
            </div>
        )
    }

    const PickupIcon = ({ props }) => {
        return (
            <div>
                <div className='fs-5 fw-bold p-1 border border-dark' title='Pickup'>
                    PU
                </div>
            </div>
        )
    }

    const ConsolidationIcon = ({ props }) => {
        return (
            <div>
                <div className='fs-5 fw-bold p-1 border border-dark' title='Consolidation, Multi Order Shipment'>
                    C
                </div>
            </div>
        )
    }

    const DeliveryIconOld = ({ props }) => {
        return (

            <div title="Delivery">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-down-left-square-fill p-1 ms-1" viewBox="0 0 16 16">
                    <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm8.096-10.803L6 9.293V6.525a.5.5 0 0 0-1 0V10.5a.5.5 0 0 0 .5.5h3.975a.5.5 0 0 0 0-1H6.707l4.096-4.096a.5.5 0 1 0-.707-.707z" />
                </svg>
            </div>

        )
    }

    const DeliveryIcon = ({ props }) => {
        return (
            <div>
                <div className='fs-5 fw-bold p-1 border border-dark' title='Delivery'>
                    D
                </div>
            </div>
        )
    }

    const DriverChanges = ({ props }) => {
        if (props.driverChanges < 4) return null
        return (
            <>
                <div className="d-flex p-1 border border-dark rounded ms-1" style={{ height: '28px' }} title={`This appointment has been rescheduled ` + props.driverChanges + ` times.`}>
                    <span className=''><strong>{props.driverChanges}</strong></span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-fill ms-1" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </div>
            </>
        )
    }

    const TruckFlatbed = ({ props }) => {
        if (!props) { return null }
        return (
            <div className="d-flex p-1 border border-dark rounded ms-1" style={{ height: '28px' }} title="This is a drop shipment">
                <div className="me-1"><strong>Drop</strong></div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-truck-flatbed " viewBox="0 0 16 16" >
                        <path d="M11.5 4a.5.5 0 0 1 .5.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-4 0 1 1 0 0 1-1-1v-1h11V4.5a.5.5 0 0 1 .5-.5zM3 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm1.732 0h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4a2 2 0 0 1 1.732 1z" />
                    </svg>
                </div>
            </div>
        )
    }

    const Truck = ({ props }) => {
        if (!props) { return null }
        return (
            <div className="d-flex flex-row p-1 border border-dark rounded ms-1" style={{ height: '28px' }} title="This is a live shipment">
                <div className='me-1'><strong>Live</strong></div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                </div>
            </div>
        )
    }

    const PendingApproval = ({ props }) => {
        if (!props) { return null }
        return (
            <div className="d-flex flex-row p-1 border border-dark rounded ms-1" style={{ height: '28px' }} title="This appointment is pending approval">
                <div className='me-1'><strong>Approval</strong></div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                    </svg>
                </div>
            </div>
        )
    }

    const SupplierRule = ({ props }) => {
        return null
        console.log('SupplierRule props', props)
        if (!props) { return null }
        if (!props.qualificationRule) { return null }
        let qualificationRule
        try {
            qualificationRule = JSON.parse(props.qualificationRule)
        } catch (error) {
            console.log('error', error)
            return null
        }
        if (qualificationRule && qualificationRule.ruleType !== 'supplier') { return null }
        return (
            <div title="Supplier Window">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-week" viewBox="0 0 16 16">
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                </svg>
            </div>
        )
    }

    const Icons = ({ props, consolidationMode }) => {
        if (!props) { return null } else {
            return (
                <div className="d-flex flex-row-reverse">
                    <DriverChanges props={props} />
                    {(props.usercode === 'momcorp' || props.usercode === 'kwiktrip') && !props.approved ? <PendingApproval props={props} /> : null}
                    {(props.usercode === 'momcorp' || props.usercode === 'kwiktrip' || props.usercode === 'sleepcountry') && props?.loadType && props.loadType === "live" ? <Truck props={props} /> : null}
                    {(props.usercode === 'momcorp' || props.usercode === 'kwiktrip' || props.usercode === 'sleepcountry') && props?.loadType && props.loadType === "drop" ? <TruckFlatbed props={props} /> : null}
                    {(props.usercode === 'momcorp' || props.usercode === 'kwiktrip') && props?.qualificationRule ? <SupplierRule props={props} /> : null}

                    {props.type === "Pickup" && showDeliveryIcons ? <PickupIcon props={props} /> : null}
                    {props.type === "Delivery" & showDeliveryIcons ? <DeliveryIcon props={props} /> : null}
                    {consolidationMode ? <ConsolidationIcon /> : null}
                </div>
            )
        }
    }

    return (
        <div className="custom-appointment">
            <div className="row text-dark" >
                <div className="col-8">
                    <div className='text-wrap'>
                        <FirstSection props={props} consolidationMode={consolidationMode} consolidationOrders={consolidationOrders} oneApptMultipleOrders={oneApptMultipleOrders} />
                    </div>
                </div>
                <div className="col-4 d-flex flex-row-reverse flex-wrap">
                    <div className="d-flex flex-column">
                        <div>
                            <Icons props={props} consolidationMode={consolidationMode} />
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <DetailsAndModal appointmentProps={props} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-end">

                </div>
            </div>
        </div>
    )
}

export default EventTemplateDay
