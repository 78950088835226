import React, { useEffect, useState } from 'react'
import ShipmentDetails from './ShipmentDetails'
import { Fade } from 'react-awesome-reveal'

export const ShipmentDetails2 = ({ shipment, isConsolidation, pickupOrDelivery }) => {

    let [consolidationShipment, setConsolidationShipment] = useState(null)
    let [state, setState] = useState('start')
    let [stop, setStop] = useState(null)

    useEffect(() => {
        if (shipment?.details?.trip && isConsolidation) {
            getShipmentDetails(shipment.details.trip)
        }
    }, [shipment, isConsolidation])

    async function getShipmentDetails(webquotecontrol) {
        setState('loading')
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://api.shippersedge.com/v4/shipment/" + webquotecontrol + '/?include=stopDetails', requestOptions)
            .then(response => response.text())
            .then(result => {
                setState('success')
                setConsolidationShipment(JSON.parse(result))
            })
            .catch(error => {
                setState('error')
                console.error('UserControl getShipmentDetails error', error)
            });
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (consolidationShipment) {
            let theStop = theStopForThisShipment(shipment.id, pickupOrDelivery, consolidationShipment)
            setStop(theStop)
        }

    }, [consolidationShipment, pickupOrDelivery, shipment])

    const theStopForThisShipment = (webquotecontrol, pickupOrDelivery, consolidationShipment) => {
        let validStops = consolidationShipment.stops.filter(stop => stop.type === pickupOrDelivery)
        for (const stop of validStops) {
            for (const order of stop.orders) {
                if (parseInt(order.id) === webquotecontrol) {
                    return stop
                }
            }
        }
        return null
    }

    const BreakoutHUs = (stop) => {
        if (stop.stop.orders) {
            return (
                stop.stop.orders.map((order, index) => {
                    return (
                        <div key={index}>
                            Order: {order.usercontrol}
                            {order.handlingUnits.map((hu, index) => {
                                return (
                                    <div key={index} className="ms-2">
                                        {hu.quantity} {capitalizeFirstLetter(hu.packaging)}
                                        <ul>
                                            {hu.items.map((item, index) => {
                                                return (

                                                    <li key={index}> {item.description} - {item.weightTotal} lbs</li>


                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })
            )
        }
        return null

    }


    if (!shipment) { return null }


    if (state === 'loading') {
        return (
            <>
                <Fade>
                    <br />
                    <div className='d-flex flex-row justify-content-center align-items-center ' onClick={() => getShipmentDetails(shipment.details.trip)} style={{ cursor: 'pointer' }}>
                        <div className="spinner-border text-primary" role="status">

                        </div>
                    </div>
                </Fade>
            </>)
    }


    if (state === 'error' && shipment.details.trip) {
        return (
            <>
                <Fade>
                    <br />
                    <div className='d-flex flex-row justify-content-center align-items-center ' onClick={() => getShipmentDetails(shipment.details.trip)} style={{ cursor: 'pointer' }}>
                        <div className="alert alert-danger border border-warning" role="alert">
                            Error loading shipment details, click to retry.
                        </div>
                    </div>
                </Fade>
            </>)
    }


    if (shipment && !isConsolidation) {
        return (<ShipmentDetails shipment={shipment} show={!isConsolidation} />)
    }

    if (state === 'start') {
        return (
            <div>ShipmentDetails2 Start</div>
        )
    }

    if (shipment && isConsolidation && stop) {
        return (
            <>
                <h4>Shipment Details</h4>
                <BreakoutHUs stop={stop} />
            </>

        )
    } else {
        return null
    }
}
