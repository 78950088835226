import React, { useState } from 'react'
import getConsolidatedShipment from '../../../../utils/helpers/getConsolidatedShipment'


import { useSearchResults } from '../../../../utils/Contexts/SearchResultsContext'

const Search = ({ usercode }) => {

    const [searchValue, setSearchValue] = useState('')

    const { searchResults, setSearchResults, searchState, setSearchState } = useSearchResults()

    const handleSearchInput = (event) => {
        if (!event) { return }

        if (event.target.value.length === 0) {
            setSearchValue('')
            setSearchResults([])
            setSearchState('start')
        }


        if (event.target.value.length > 3) {
            setSearchValue(event.target.value)
        }
    }

    const search = async (event) => {
        if (!event) { return }
        if (!searchValue) { return }

        if (event.key === 'Enter' && searchValue.length > 4) {
            setSearchState('loading')
            let searchResultLimit = 100
            let hardcodedOperator = 'like'
            let categoryFields = ['m.webquotecontrol', 'cs.pronum', 'cs.invoiceNum', 'm.usercontrol', 'm.shipord1', 'm.shipord2', 'm.shipord3', 'm.shipord4', 'm.shipord5', 'm.shipord6']
            const categoryFieldsList = categoryFields.join(',')
            let limit = "limit=" + searchResultLimit
            let include = "&include=stopDetails,handlingUnits"
            let category1 = "&category1=" + categoryFieldsList
            let operator1 = "&operator1=" + hardcodedOperator
            let search1 = "&search11=" + searchValue
            let client = "&client=" + usercode

            // DEBUGGING ONLY
            // client = "&client=kwiktrip"

            let url = "https://api.shippersedge.com/v4/shipment/?" + limit + client + category1 + operator1 + search1 + include
            // console.log('Search search url', url)
            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
                }
            })
            let data = await response.json()

            // look for appt detail for given order
            for (const ship of data.data) {
                let apptDetails = await getApptDetails(ship.id)
                ship.apptDetails = apptDetails
            }

            // look for consolidation shipment
            for (const ship of data.data) {
                if (ship && ship?.details?.trip) {
                    ship.isConsolidation = true
                    let consolidationShipment = await getConsolidatedShipment(ship.details.trip, ship?.id, null)
                    ship.consolidationShipment = consolidationShipment
                }
            }
            setSearchResults(data.data)
            setSearchState('success')
        }
    }


    const getApptDetails = async (webquotecontrol) => {

        let url = `https://api.shippersedge.com/schedule/events/?webquotecontrol=` + webquotecontrol

        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            }
        })

        let data = await response.json()
        console.log('Search getApptDetails data', data)
        return data
    }

    return (
        <div>
            <div className="form-floating">
                <input
                    type="search"
                    className="form-control"
                    id="search"
                    placeholder="Search Appointments"
                    autoComplete='off'
                    onChange={(event) => handleSearchInput(event)}
                    onKeyDown={(event) => search(event)}
                />
                <label htmlFor="search">Search Appointments</label>
            </div>
        </div>
    )
}

export default Search