import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import useManager from './useManager'
import Navbar from '../NavBar/NavBar'
import DocksManagerSchedules from './Components/DocksManagerSchedules'
import {LoadingOverlay} from '@achmadk/react-loading-overlay';
import PoweredByFooter from '../Footer/PoweredByFooter'
import Loading from '../Carrier/Components/Loading'
import Updating from '../Carrier/Components/Updating'
import Unauthorized from '../Carrier/Components/Unauthorized'
import DockSelector from './Components/DockSelector'
import UnScheduledShipments from './Components/UnScheduledShipments/UnScheduledShipments'
// import { select } from '@syncfusion/ej2-schedule'
import { SelectedDateProvider } from '../../utils/Contexts/SelectedDateContext'

import UnapprovedAppts from './Components/UnapprovedAppts/UnapprovedAppts'
import ModeDropDown from './Components/ModeDropDown/ModeDropDown'
import AuthUI from './Components/AuthUI/AuthUI'
// import { UpdatesStatus } from './Components/UpdatesStatus/UpdatesStatus'



const Manager = (props) => {

    const { pageMode, docks, setDocks, usercode, status, setStatus, getManagerView, updatingOverlayStatus, setUpdatingOverlayStatus, debug, refreshData, unScheduledShipments, userRole, locationSettings, location, lastUpdateDate } = useManager(props)

    const [selectedDock, setSelectedDock] = useState(null)

    const [managerMode, setManagerMode] = useState('calendar')

    const [managerModeBtnText, setManagerModeBtnText] = useState('Show Unscheduled Shipments')

    // https://ej2.syncfusion.com/react/documentation/schedule/resources/
    const [ownerData, setOwnerData] = useState(null)


    const contextValue = { docks, setDocks };

    useEffect(() => {
        // Setting the selected dock to be the first dock by default only if no dock is yet selected
        if (unScheduledShipments) {
            // console.log('unScheduledShipments', unScheduledShipments)
            let pickup = 0
            let delivery = 0
            if (unScheduledShipments.pickup.length) {
                pickup = unScheduledShipments.pickup.length
            }
            if (unScheduledShipments.delivery.length) {
                delivery = unScheduledShipments.pickup.length
            }
            let total = pickup + delivery
            setManagerModeBtnText('Show Unscheduled Shipments (' + total + ')')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unScheduledShipments])

    

    useEffect(() => {
        // Setting the selected dock to be the first dock by default only if no dock is yet selected
        if (docks && !selectedDock) {
            setSelectedDock(docks[0])
        }

        // Setting owner data (this assigns colors and "owners" to various docks so that syncusion can display them together using ResourcesDirective) https://ej2.syncfusion.com/react/documentation/schedule/resources/
        let colors = [
            { border: "#0B7EEB", background: "#cee6fd" },
            { border: "#15A918", background: "#d2f9d2" },
            { border: "#7107C3", background: "#e9cefd" },
            { border: "#000000", background: "#e6e6e6" },
            { border: "#EF412A", background: "#fbd5d0" },
            { border: "#798BAA", background: "#e0e4eb" },
            { border: "#DF18E3", background: "#f9d1fa" },
            { border: "#0B7EEB", background: "#cee6fd" },
            { border: "#15A918", background: "#d2f9d2" },
            { border: "#7107C3", background: "#e9cefd" },
            { border: "#000000", background: "#e6e6e6" },
            { border: "#EF412A", background: "#fbd5d0" },
            { border: "#798BAA", background: "#e0e4eb" },
            { border: "#DF18E3", background: "#f9d1fa" }
        ]

        if (docks && !selectedDock) {

            let docksNo9999 = docks.filter(dock => {
                if (dock.id !== 99999999999999) {
                    return dock
                }
            })
            let generatedOwnerData = docksNo9999.map((dock, index) => {
                let color = colors[index].background
                let ownerD = {}
                ownerD.OwnerText = dock.name
                ownerD.id = dock.id
                ownerD.OwnerColor = color
                return ownerD
            })


            setOwnerData(generatedOwnerData)

        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docks])

    // console.log('managerMode', managerMode)

    if (pageMode === 'running' && docks && selectedDock && ownerData) {
        if (managerMode === 'calendar') {
            return (
                <>

                    <SelectedDateProvider>
                        
                            <LoadingOverlay
                                active={updatingOverlayStatus}
                                spinner
                                text='Updating...'
                            >
                                <Navbar usercode={usercode} status={status} docks={docks} selectedDock={selectedDock} userRole={userRole} lastUpdateDate={lastUpdateDate} location={location} managerMode={managerMode} setManagerMode={setManagerMode} />
                                <AuthUI />
                                <Container>
                                    <Col>

                                        <Row>
                                            <hr className='mb-3 mt-2' />
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>

                                            <DockSelector docks={docks} selectedDock={selectedDock} setSelectedDock={setSelectedDock} />

                                            {docks.map((dock, index) => {
                                                if (JSON.stringify(dock.name) === JSON.stringify(selectedDock.name)) {

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <DocksManagerSchedules selectedDock={dock} docks={docks} usercode={usercode} setUpdatingOverlayStatus={setUpdatingOverlayStatus} getManagerView={getManagerView} setStatus={setStatus} refreshData={refreshData} ownerData={ownerData} userRole={userRole} locationSettings={locationSettings} />
                                                        </React.Fragment>
                                                    )
                                                }
                                            })}

                                        </Row>

                                    </Col>
                                </Container>
                                <PoweredByFooter />
                            </LoadingOverlay>
                    </SelectedDateProvider >

                </>)
        }

        if (managerMode === 'unScheduledShipments') {
            return (
                <SelectedDateProvider>
                    <LoadingOverlay
                        active={updatingOverlayStatus}
                        spinner
                        text='Updating...'
                    >
                        <Navbar status={status} docks={docks} selectedDock={selectedDock} />

                        <Container>

                            <Row>
                                <div className="d-flex flex-row">
                                    <ModeDropDown managerMode={managerMode} setManagerMode={setManagerMode} />
                                </div>
                            </Row>

                            <UnScheduledShipments unScheduledShipments={unScheduledShipments} />
                        </Container>
                        <PoweredByFooter />
                    </LoadingOverlay>
                </SelectedDateProvider>
            )
        }

        if (managerMode === 'unapprovedAppointments') {
            return (
                <SelectedDateProvider>
                    <LoadingOverlay
                        active={updatingOverlayStatus}
                        spinner
                        text='Updating...'
                    >
                        <Navbar status={status} docks={docks} selectedDock={selectedDock} />

                        <Container>
                            <Row>
                                <div className="d-flex flex-row">
                                    <ModeDropDown managerMode={managerMode} setManagerMode={setManagerMode} />
                                </div>
                            </Row>

                            <UnapprovedAppts />
                        </Container>
                        <PoweredByFooter />
                    </LoadingOverlay>
                </SelectedDateProvider>
            )
        } else {
            return (<div>WAT</div>)
        }
    } else if (pageMode === 'updating') {
        return (
            <>
                <Navbar />
                <Container>
                    <Col>
                        <Row>
                            <Updating />
                        </Row>
                    </Col>
                </Container>
            </>
        )

    } else if (pageMode === 'unauthorized') {
        return (
            <>
                <Navbar />
                <Container>
                    <Col>
                        <Row>
                            <Unauthorized />
                        </Row>
                    </Col>
                </Container>
            </>
        )
    } else {
        return (
            <>
                <Navbar />
                <Container>
                    <Col>
                        <Row>
                            <Loading />
                        </Row>
                    </Col>
                </Container>
            </>)
    }
}

export default Manager
