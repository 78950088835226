import React, { useState, useEffect } from 'react'
import { Fade } from 'react-awesome-reveal';
// https://www.react-awesome-reveal.com/examples/
import deriveStopInfoFromConsolidationWithOrder from '../../../../utils/helpers/deriveStopInfoFromConsolidationWithOrder';

const ApptEditorTemplateShipmentDetails = ({ shipment, isConsolidation, consolidationShipment, appointmentInfo, multiShipmentApptMode }) => {
    // console.log('ApptEditorTemplateShipmentDetails.js : ', shipment, isConsolidation, consolidationShipment, appointmentInfo, multiShipmentApptMode)
    if (multiShipmentApptMode) {
        // console.log('ApptEditorTemplateShipmentDetails.js - multiShipmentApptMode is true')
        return null
    }
    if (isConsolidation && !consolidationShipment) {
        return null
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const PickupInfo = ({ shipment }) => {
        if (!shipment) { return null }
        // 
        return (
            <div>
                {shipment.shipper.address.city && shipment.shipper.address.city !== "" ? <>{shipment.shipper.address.city},&nbsp; </> : null}
                {shipment.shipper.address.state && shipment.shipper.address.state !== "" ? <>{shipment.shipper.address.state}&nbsp; </> : null}
                {(shipment.shipper.address.city === "" && shipment.shipper.address.state === "") || (!shipment.shipper.address.city && !shipment.shipper.address.state) ? <><div className=' rounded p-1' style={{ backgroundColor: '#e2e3e5' }} >Unknown</div></> : null}
            </div>
        )
    }

    const DeliveryInfo = ({ shipment }) => {
        if (!shipment) { return null }
        // 
        return (
            <div>
                {shipment.consignee.address.city && shipment.consignee.address.city !== "" ? <>{shipment.consignee.address.city},&nbsp; </> : null}
                {shipment.consignee.address.state && shipment.consignee.address.state !== "" ? <>{shipment.consignee.address.state}&nbsp; </> : null}
                {(shipment.consignee.address.city === "" && shipment.consignee.address.state === "") || (!shipment.consignee.address.city && !shipment.consignee.address.state) ? <><div className=' rounded p-1' style={{ backgroundColor: '#e2e3e5' }} >Unknown</div></> : null}
            </div>
        )
    }


    if (!shipment) {
        return (
            <div id="ApptEditorTemplateShipmentDetailsLoading" className='d-flex flex-row justify-content-center align-items-center flex-grow-1'>
                <div className="spinner-border text-primary m-5" role="status" style={{ width: "3rem", height: "3rem" }}>
                </div>
            </div>
        )
    }


    if (isConsolidation && consolidationShipment && shipment.id) {
        let stopInfo = deriveStopInfoFromConsolidationWithOrder(consolidationShipment, shipment.id)
        // console.log('deriveStopInfoFromConsolidationWithOrder stopInfo', stopInfo)

        return (
            <>
                <Fade >
                    <div>
                        <div className="row">
                            <div className="col-sm-5 d-flex justify-content-center">

                                {stopInfo.pickup.name ? stopInfo.pickup.name : null}<br />
                                {stopInfo.pickup.address.line1 ? stopInfo.pickup.address.line1 : null}<br />
                                {stopInfo.pickup ? stopInfo.pickup.address.city : null}, {stopInfo.pickup ? stopInfo.pickup.address.state : null}
                            </div>
                            <div className="col-sm-2 d-flex justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                            </div>
                            <div className="col-sm-5 d-flex justify-content-center">
                                {stopInfo.delivery.name ? stopInfo.delivery.name : null}<br />
                                {stopInfo.delivery.address.line1 ? stopInfo.delivery.address.line1 : null}<br />
                                {stopInfo.delivery ? stopInfo.delivery.address.city : null}, {stopInfo.delivery ? stopInfo.delivery.address.state : null}
                            </div>
                        </div>
                        {shipment?.supplier?.name ? <div className="row mt-1">
                            <div className="col-sm-12 d-flex justify-content-center">
                                <div className='mediumFontSize'>{shipment.supplier.name}</div>
                            </div>
                        </div> : null}

                        <div className="row">
                            <div className="col-sm-12 ">
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-start">
                                {appointmentInfo && appointmentInfo.type === "Pickup" && stopInfo.pickup.orders.length ?
                                    <>

                                        {stopInfo.pickup.orders.map((order, index) => {
                                            return (
                                                <>
                                                    {order.usercontrol} <br />
                                                </>
                                            )
                                        })}
                                    </>
                                    : null}

                                {(!appointmentInfo) || appointmentInfo.type === "Delivery" ?
                                    <>
                                        {stopInfo.delivery.orders.map((order, index) => {
                                            return (
                                                <div className='card m-1'>
                                                    <div className='card-body'>
                                                        <span className='mediumFontSize'>{order.usercontrol} </span><br />
                                                        <hr />
                                                        {order.handlingUnits.map((hu, index) => {
                                                            return (
                                                                <>
                                                                    <div className=''>{hu.quantity} {capitalizeFirstLetter(hu.packaging)}</div>
                                                                    {hu.items.map((item, index) => {
                                                                        return (
                                                                            <div className='ms-2'>
                                                                                {item.quantity} {item.description} <br />{item.weightTotal} lbs
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                    : null}
                            </div>
                        </div>
                    </div>
                </Fade>
            </>
        )
    }


    if (shipment && !isConsolidation) {
        return (
            <>
                <Fade >
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-5 d-flex justify-content-center">
                                    <PickupInfo shipment={shipment} />
                                </div>
                                <div className="col-sm-2 d-flex justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                    </svg>
                                </div>
                                <div className="col-sm-5 d-flex justify-content-center">
                                    <DeliveryInfo shipment={shipment} />
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-sm-12 d-flex justify-content-center">
                                    {shipment.details?.equipment?.length > 0 ? <span>   {shipment.details.equipment.map((equip, index) => {
                                        return (<span key={index}>{capitalizeFirstLetter(equip)}&nbsp;</span>)
                                    })}</span> : null}
                                </div>
                            </div>
                            {shipment?.carrier?.name ? <div className="row mt-1">
                                <div className="col-sm-12 d-flex justify-content-center ">
                                    <span className=''>{shipment.carrier.name}</span>
                                </div>
                            </div> : null}
                            {shipment?.supplier?.name ? <div className="row mt-1">
                                <div className="col-sm-12 d-flex justify-content-center">
                                    <span className='mediumFontSize'>{shipment.supplier.name}</span>
                                </div>
                            </div> : null}
                            <div className="row ">
                                <div className="col-sm-12">

                                    {shipment.handlingUnits.length > 0 ? <hr /> : <div className='border border-secondary rounded p-1 mt-2' style={{ backgroundColor: '#e2e3e5' }}>No handling unit or item information available for {shipment.id}</div>}

                                    {shipment.handlingUnits.map((hu, index) => {
                                        return (
                                            <div key={index}>
                                                <span>{hu.quantity} {capitalizeFirstLetter(hu.packaging)}</span>
                                                {hu.items.map((item, index) => {
                                                    return (
                                                        <div key={index} className='ms-3'>
                                                            {item.quantity} &nbsp;
                                                            {item.description} &nbsp;
                                                            {item.weightTotal} lbs
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade >
            </>
        )
    } else {
        return (

            <div id="ApptEditorTemplateShipmentDetailsLoading" className='d-flex flex-row justify-content-center align-items-center flex-grow-1'>
                <div className="spinner-border text-primary m-5" role="status" style={{ width: "3rem", height: "3rem" }}>
                </div>
            </div>

        )
    }
}

export default ApptEditorTemplateShipmentDetails