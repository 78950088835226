import React, { useState, useEffect } from 'react'

const StatusAlert = ({ status, address, userRole }) => {

    const [showMessage, setShowMessage] = useState(true)

    useEffect(() => {
        console.log('status', status)
        if (showMessage === false) {
            setShowMessage(true)
        }
        const timer = setTimeout(() => {
            setShowMessage(false)
        }, 3000);
        return () => clearTimeout(timer);
    }, [status]);  // eslint-disable-line

    // Resets the status box to clear after 5 seconds
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         console.log('clear')
    //         setShowMessage(true)
    //     }, 5000);
    //     return () => clearTimeout(timer);
    // }, []);


    if (status) {

        if (showMessage === false) {
            return (
                <div className="col-3 flex-fill mr-2 fade-in-image d-flex flex-column justify-content-center align-items-center f">
                    <div>
                        {/* <img src={"https://www2.shippersedge.com//images/logos/" + address.logo} alt={address.customerName + " logo"} height="60" /> */}
                    </div>
                    {userRole === 'readonly' ?
                        <div>
                            <small>Read only mode
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-lock-fill ml-3" viewBox="0 0 16 16">
                                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                                </svg>
                            </small>
                        </div> : null}
                </div>)
        }

        if (status.includes('start')) {
            return (<div className="col-3 flex-fill mr-2" style={{ marginTop: '1rem' }}>
                <div className={`alert alert-primary mr-2 ${showMessage ? 'alert-shown' : 'alert-hidden'}`} id="alert1">
                    Welcome!
                </div>
            </div>)
        }

        if (status.includes('clear')) {
            return (<div className="col-3 flex-fill mr-2"></div>)
        }

        if (status.includes('saved')) {
            return (
                <div className="col-3 flex-fill " style={{ marginTop: '1rem' }}>
                    <div className={`alert alert-success mr-2 ${showMessage ? 'alert-shown' : 'alert-hidden'}`} id="alert1">Changes Saved!</div>
                </div>
            )
        }

        if (status.includes('updating')) {
            return (
                <div className="col-3 flex-fill" style={{ marginTop: '1rem' }}>
                    <div className={`alert alert-info mr-2`} id="alert1">Updating!</div>
                </div>
            )
        }

        if (status.includes('error')) {
            return (
                <div className="col-3 flex-fill" style={{ marginTop: '1rem' }}>
                    <div className={`alert alert-danger mr-2 ${showMessage ? 'alert-shown' : 'alert-hidden'}`} id="alert1"><span className="font-weight: bold">ERROR encountered.</span></div>
                </div>
            )
        }

        else {
            return (<div className="col-3 flex-fill mr-2"></div>)
        }
    } else { return null }
}

export default StatusAlert
