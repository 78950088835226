import React from 'react'
import StatusAlert from './StatusAlert/StatusAlert'
import PrintScheduleBtn from '../Manager/Components/PrintScheduleBtn'
import ModeDropDown from '../Manager/Components/ModeDropDown/ModeDropDown'
import Search from '../Manager/Components/Search/Search'
import { SearchResultsProvider } from '../../utils/Contexts/SearchResultsContext'
import SearchResults from '../Manager/Components/Search/SearchResults'
import { SelectedDateProvider } from '../../utils/Contexts/SelectedDateContext'

const NavBar = ({ status, docks, userRole, usercode, location, selectedDock, managerMode, setManagerMode }) => {
    let humanReadableToday = new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })
    let address = null
    if (docks) {
        if (docks[0].name === "All Docks") {
            address = docks[1]
        } else {
            address = docks[0]
        }

    }
    return (
        <SelectedDateProvider>
            <SearchResultsProvider>

                <div className="d-flex justify-content-center align-items-stretch container">

                    <div className="col-3 d-flex flex-row justify-content-center align-items-center">
                        {address ? (
                            <div>
                                <div className="xlargeFontSize">{address.dockLocationName}</div>
                                <div><PrintScheduleBtn usercode={usercode} location={location} selectedDock={selectedDock} humanReadableToday={humanReadableToday} /></div>
                            </div>
                        ) : null}

                    </div>



                    <div className="col-6">
                        {address ?
                            <div style={{ marginTop: '1rem' }}>
                                <img src={"https://www2.shippersedge.com//images/logos/" + address.logo} alt={address.customerName + " logo"} height="60" />
                                <h5> Dock Scheduler</h5>
                            </div>
                            : null}

                    </div>
                    <div className="col-3 mt-1">
                    {
                        address ? 
                        <>
                            <Search usercode={usercode} />
                            <ModeDropDown managerMode={managerMode} setManagerMode={setManagerMode} />
                        </>: 
                        null
                    }
                    </div>
                    

                </div>
                
                <div className="d-flex justify-content-center align-items-stretch container">
                    <StatusAlert status={status} address={address} userRole={userRole} />
                </div>
                <SearchResults />
            </SearchResultsProvider>
        </SelectedDateProvider>
    )
}

export default NavBar
